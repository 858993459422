<template>
  <div class="preview">
    <!--   Header   -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ texts.pageTitle }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>

    <CTabs class="zq--tabs">
<!--  <CTab title="Statistics">
        <Info/>
      </CTab>-->
      <CTab title="Events">
        <EventsTable/>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
// import EventsTable from '@/generated/ziqni/views/events/EventsTable';
import EventsTable from '@/views/ziqni/events/details/EventsTable';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { events } from '@/config/descriptions/events';
import { eventsTexts } from '@/config/pageTexts/events.json';

export default {
  name: 'Events',
  components: {
    EventsTable,
    // Info,
    IconWithTooltip
  },
  data() {
    return {
      texts: {
        ...eventsTexts
      },
      descriptions: {
        ...events
      },
    }
  },
  methods: {
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);
    }
  },
  created() {
    this.initialize()
  },
};
</script>

<style lang="scss">
.preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preview-tabs {
  flex: 2;
  background: #ebedef;
}

</style>
