<template>
  <div class="json-load">
    <v-jsoneditor
        v-model="json"
        height="380px"
        :plus="plus"
        :options="options"
        @error="onError"/>
  </div>
</template>

<script>
import VJsoneditor from 'v-jsoneditor';
import {getUTCTime} from '@/utils/timeUtils';

export default {
  components: {
    VJsoneditor
  },
  props: {
    jsonProp: {
      type: Array,
      default() {
        return []
      }
    },
    plus: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default() {
        return 'code';
      }
    }
  },
  data() {
    return {
      options: {
        search: false,
        mode:  this.mode,
        enableTransform: false,
        onValidate: function (json) {
          const errors = [];
          json.map(item => {
            if (item && !item.memberRefId) {
              errors.push({
                path: ['memberRefId'],
                message: 'Required property "memberRefId" missing.'
              });
            }

            if (item && !item.action) {
              errors.push({
                path: ['action'],
                message: 'Required property "action" missing.'
              });
            }

            if (item && !item.entityRefId) {
              errors.push({
                path: ['entityRefId'],
                message: 'Required property "entityRefId" missing.'
              });
            }

            if (item && !item.sourceValue) {
              errors.push({
                path: ['sourceValue'],
                message: 'Required property "sourceValue" missing.'
              });
            }

            if (item && !item.transactionTimestamp) {
              errors.push({
                path: ['transactionTimestamp'],
                message: 'Required property "transactionTimestamp" missing.'
              });
            }
          })

          return errors;
        }
      },
      json: [
        {
          "memberRefId": "Player-1",
          "batchId": "ecrsamQm23r61KgaPQq7x",
          "action": "buy",
          "entityRefId": "apples",
          "unitOfMeasure": null,
          "sourceValue": "12.345",
          "transactionTimestamp": new Date(),
          "tags": [
            "Dc4swmQBVd51K6gPQqFx",
            "Dc4swmQBVd51K6gPQqF2"
          ],
          "eventRefId": null,
          "memberId": null,
          "customFields": { }
        }
      ]
    }
  },
  methods: {
    onError() {
      console.log('error');
    },
    validate(item) {
      console.log(item)
    }
  },
  created() {
    if (this.jsonProp.length) {
      this.json = this.jsonProp;
    }
    this.$emit('change-json', this.json);
  },
  watch: {
    json(val) {
      this.$emit('change-json', val);
    }
  }
}
</script>

<style lang="scss">
.json-load {
  height: 100%;

  .json-load-tabs {
    flex: 2;
    background: #ebedef;

    .nav.nav-tabs {
      background: white;
      padding: 0 1.5rem;
    }


    .tabs-content {
      min-height: 300px;
    }
  }

  // Hide label powered By
  //.jsoneditor-poweredBy{
  //  display: none;
  //}

}
</style>